import React, {useEffect, useState} from 'react'
import {
    Content,
    Header,
    Page
} from "@backstage/core-components";
import {
    identityApiRef,
    useApi
} from "@backstage/core-plugin-api";
import * as O from 'fp-ts/Option'
import {LoggedInUser} from "./models";
import {formatTitle, fetchLoggedProfile} from "./utils";
import {WelcomeTitle} from "@backstage/plugin-home";
import HeaderClocks from "../HeaderClocks";
import CampFire from "../CampFire";
import {Container, makeStyles, Paper} from "@material-ui/core";
import {SearchBar} from "@backstage/plugin-search-react";
import {SearchModal} from "@backstage/plugin-search";

const useStyles = makeStyles({
    search: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
        borderRadius: '50px',
        margin: 'auto',
    },
});

export const MainPage = () => {
    const classes = useStyles();

    const identityApi = useApi(identityApiRef)

    const [isSearchOpen, setSearchOpen] = useState(false)
    const [userProfile, setUserProfile] = useState<LoggedInUser>(O.none)

    const defaultUsername = 'Stranger'
    const title = formatTitle(userProfile, defaultUsername)

    useEffect(() => {
        fetchLoggedProfile(identityApi).then(setUserProfile)
    }, [identityApi])

    return (
        <Page themeId="home">
            <Header title={<WelcomeTitle/>} pageTitleOverride={title}>
                <HeaderClocks />
            </Header>

            <Content>

                <CampFire/>
                <Container>
                    <Paper className={classes.search}>
                        <SearchBar clearButton={false} onClick={() => setSearchOpen(true)}/>
                    </Paper>
                </Container>

                <SearchModal open={isSearchOpen} toggleModal={() => setSearchOpen(!isSearchOpen)}/>
            </Content>
        </Page>
    )
}
