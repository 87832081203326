import React from 'react'
import {Content, Header, Page} from "@backstage/core-components";
import {HomePageToolkit} from "@backstage/plugin-home";
import tools from './tools'
import {Grid} from "@material-ui/core";

export default () => (<Page themeId="tools">
    <Header title="Useful tools and services"/>
    <Content>
        <Grid container spacing={3}>
            {Object
                .keys(tools)
                .map(section =>
                    (<Grid item xs={12}>
                        <HomePageToolkit title={section} key={section} tools={tools[section]}/>
                    </Grid>)
                )}
        </Grid>
    </Content>
</Page>)
