import React from 'react'

import './index.css'

// From https://codepen.io/pehaa/pen/MLPgJv
export default () => (<div className="clip">
    <div className="mountain-4"/>
    <div className="mountain-3"/>
    <div className="cloud cloud1"/>
    <div className="mountain-1"/>
    <div className="mountain-2"/>
    <div className="tree tree2"/>
    <div className="tree tree1"/>
    <div className="tree tree0"/>
    <div className="cloud"/>
    <div className="tree tree3"/>
    <div className="tree tree4"/>
    <div className="tree tree5"/>
    <div className="close-2"/>
    <div className="close-1"/>
    <div className="dry-container">
        <div className="dry"/>
        <div className="dry dry1"/>
        <div className="dry dry2"/>
        <div className="dry dry11"/>
        <div className="dry dry22"/>
    </div>
    <div className="dry-container dry0">
        <div className="dry"/>
        <div className="dry dry1"/>
        <div className="dry dry2"/>
        <div className="dry dry11"/>
        <div className="dry dry22"/>
    </div>
</div>)
