import * as O from 'fp-ts/Option'
import {BackstageUserIdentity, ProfileInfo} from "@backstage/core-plugin-api";

export type LoggedInUser = O.Option<ProfileInfo & BackstageUserIdentity>

export const caredKind = ["Component", "System", "Domain", "Api"] as const

export type CaredKind = typeof caredKind[number]

export const isCaredKind = (kind: string): kind is CaredKind =>
    caredKind.map(s => s.toLocaleLowerCase()).includes(kind.toLocaleLowerCase())

export type CaredKindCount = Record<CaredKind, number>
