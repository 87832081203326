import {
    EntityDependsOnComponentsCard,
    EntityDependsOnResourcesCard,
    EntityLayout
} from "@backstage/plugin-catalog";
import {EntityGitlabContent, isGitlabAvailable} from "@immobiliarelabs/backstage-plugin-gitlab";
import {EntityCicdStatisticsContent} from "@backstage/plugin-cicd-statistics";
import {Grid} from "@material-ui/core";
import {EntityConsumedApisCard, EntityProvidedApisCard} from "@backstage/plugin-api-docs";
import React from "react";
import techdocsContent from "../../Content/TechdocsContent";
import ciCdContent from "../../Content/CiCdContent";
import overviewContent from "../../Content/OverviewContent";

export default (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
            {ciCdContent}
        </EntityLayout.Route>

        <EntityLayout.Route
            if={isGitlabAvailable}
            path="/gitlab"
            title="Gitlab"
        >
            <EntityGitlabContent />
        </EntityLayout.Route>

        <EntityLayout.Route
            if={isGitlabAvailable}
            path="/ci-cd-statistics" title="CI/CD Statistics">
            <EntityCicdStatisticsContent/>
        </EntityLayout.Route>;

        <EntityLayout.Route path="/api" title="API">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityProvidedApisCard/>
                </Grid>
                <Grid item md={6}>
                    <EntityConsumedApisCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
)
