import React from "react";
import {
    EntityDependsOnComponentsCard,
    EntityDependsOnResourcesCard,
    EntityLayout
} from "@backstage/plugin-catalog";
import {EntityGitlabContent, isGitlabAvailable} from "@immobiliarelabs/backstage-plugin-gitlab";
import {Grid} from "@material-ui/core";
import techdocsContent from "../../Content/TechdocsContent";
import overviewContent from "../../Content/OverviewContent";
import ciCdContent from "../../Content/CiCdContent";

export default (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route
            if={isGitlabAvailable}
            path="/gitlab"
            title="Gitlab"
        >
            <EntityGitlabContent />
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
            {ciCdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);
