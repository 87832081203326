import * as O from 'fp-ts/Option'
import {pipe, identity} from 'fp-ts/function'
import {caredKind, CaredKindCount, isCaredKind, LoggedInUser} from "./models";
import {Entity} from "@backstage/catalog-model";
import {IdentityApi} from "@backstage/core-plugin-api";
import {CatalogApi} from "@backstage/plugin-catalog-react";

export const formatTitle = (profile: LoggedInUser, defaultUsername: string) => pipe(
    profile,
    O.chain(({displayName}) => O.fromNullable(displayName)),
    O.getOrElse(() => defaultUsername),
    username => `Hello ${username}`
)

export const countEntityKind = (entities: Entity[]): CaredKindCount => {
    const startCount: CaredKindCount = {
        Api: 0,
        System: 0,
        Domain: 0,
        Component: 0
    }
    if (entities.length === 0) return startCount

    return entities.reduce((acc, next) => {
        if (isCaredKind(next.kind)) acc[next.kind] = acc[next.kind] + 1
        return acc
    }, startCount)
}

export const fetchLoggedProfile = (identityApi: IdentityApi): Promise<LoggedInUser> =>
    Promise
        .all([identityApi.getProfileInfo(), identityApi.getBackstageIdentity()])
        .then(([profile, backstageProfile]) => O.some({
            ...profile,
            ...backstageProfile
        }))

export const fetchCaredEntityForUser = (catalogApi: CatalogApi, userProfile: LoggedInUser): Promise<Entity[]> =>
    catalogApi
        .getEntities({
            filter: [
                {kind: caredKind.map(identity)},
                pipe(
                    userProfile,
                    O.map(({ownershipEntityRefs}) => ({ownershipEntityRefs: ownershipEntityRefs})),
                    O.getOrElse(() => ({}))
                )
            ]
        })
        .then(({items}) => items)
