import React from 'react'
import {Tool} from "@backstage/plugin-home";
import {AiOutlineGitlab} from "react-icons/ai";
import {BsBucket} from "react-icons/bs";
import {IoLogoDiscord} from "react-icons/io5";

const tools: Record<string, Tool[]> = {
    Development: [
        {
            url: 'https://gitlab.beyond-undefined.fr/',
            label: 'Gitlab',
            icon: <AiOutlineGitlab />
        },
        {
            url: 'https://discord.gg/5U25TmcZ7H',
            label: 'Discord',
            icon: <IoLogoDiscord />
        }
    ],
    Operations: [
        {
            url: 'https://console.minio.beyond-undefined.fr',
            label: 'Minio',
            icon: <BsBucket />
        }
    ]
}

export default tools
