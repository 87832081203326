import React from 'react';
import {
    EntitySwitch,
    isKind
} from '@backstage/plugin-catalog';
import ComponentPage from "./Page/ComponentPage";
import defaultEntityPage from "./Page/DefaultEntityPage";
import ApiPage from "./Page/ApiPage";
import GroupPage from "./Page/GroupPage";
import SystemPage from "./Page/SystemPage";
import DomainPage from "./Page/DomainPage";
import UserPage from "./Page/UserPage";

export const entityPage = (
    <EntitySwitch>
        <EntitySwitch.Case if={isKind('component')} children={ComponentPage}/>
        <EntitySwitch.Case if={isKind('api')} children={ApiPage}/>
        <EntitySwitch.Case if={isKind('group')} children={GroupPage}/>
        <EntitySwitch.Case if={isKind('user')} children={UserPage}/>
        <EntitySwitch.Case if={isKind('system')} children={SystemPage}/>
        <EntitySwitch.Case if={isKind('domain')} children={DomainPage}/>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);
