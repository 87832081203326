import React from 'react'
import {HeaderWorldClock} from "@backstage/plugin-home";

export default () => (<HeaderWorldClock
    clockConfigs={[
        {
            label: 'MTR',
            timeZone: 'America/Montreal',
        },
        {
            label: 'AMS',
            timeZone: 'Europe/Amsterdam',
        },
        {
            label: 'PRS',
            timeZone: 'Europe/Paris',
        },
    ]}
    customTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }}
/>)
