import {EntityLayout} from "@backstage/plugin-catalog";
import {Grid} from "@material-ui/core";
import entityWarningContent from "../Content/EntityWarningContent";
import {
    EntityGroupProfileCard,
    EntityMembersListCard,
    EntityOwnershipCard
} from "@backstage/plugin-org";
import React from "react";

export default (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityGroupProfileCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityOwnershipCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12}>
                    <EntityMembersListCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayout>
);
