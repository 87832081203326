import React from "react";
import {EntityLayout} from "@backstage/plugin-catalog";
import techdocsContent from "../Content/TechdocsContent";
import overviewContent from "../Content/OverviewContent";

export default (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);
