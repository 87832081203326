import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import Build from "@material-ui/icons/Build";

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
    Settings as SidebarSettings,
    UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarSpace,
    useSidebarOpenState,
    Link, GroupIcon, CatalogIcon, DashboardIcon
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {MyGroupsSidebarItem} from "@backstage/plugin-org";

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                {/* Global nav, not org-specific */}
                <SidebarItem icon={DashboardIcon} to="/you" text="Beyond You"/>
                <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog"/>

                <MyGroupsSidebarItem
                    singularTitle="My Squad"
                    pluralTitle="My Squads"
                    icon={GroupIcon}
                />
                <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                {/* End global nav */}
                <SidebarDivider/>
                <SidebarItem icon={Build} to="/tools" text="Tools"/>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings"
            >
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
