import React from "react";
import {Grid} from "@material-ui/core";
import {
    EntityAboutCard,
    EntityHasSubcomponentsCard,
    EntityLinksCard,
    EntitySwitch
} from "@backstage/plugin-catalog";
import {EntityCatalogGraphCard} from "@backstage/plugin-catalog-graph";
import {
    EntityGitlabMergeRequestsTable,
    EntityGitlabPeopleCard,
    EntityGitlabPipelinesTable,
    isGitlabAvailable
} from "@immobiliarelabs/backstage-plugin-gitlab";
import entityWarningContent from "./EntityWarningContent";

const GRID_SPACING = 3

export default (
    <Grid container spacing={GRID_SPACING} alignItems="stretch">
        {entityWarningContent}

        <Grid item xl={4} md={8} xs={12}>
            <EntityAboutCard variant="gridItem"/>
        </Grid>

        <Grid item xl={4} md={4} xs={12}>
            <Grid container spacing={GRID_SPACING} alignItems="stretch">
                <EntitySwitch>
                    <EntitySwitch.Case if={isGitlabAvailable}>
                        <Grid item xs={12}>
                            <EntityGitlabPeopleCard/>
                        </Grid>
                    </EntitySwitch.Case>
                </EntitySwitch>

                <Grid item xs={12}>
                    <EntityLinksCard/>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xl={4} md={12} xs={12}>
            <Grid container spacing={GRID_SPACING} alignItems="stretch">
                <Grid item xl={12} md={6} xs={12}>
                    <EntityCatalogGraphCard variant="gridItem" height={200}/>
                </Grid>
                <Grid item xl={12} md={6} xs={12}>
                    <EntityHasSubcomponentsCard variant="gridItem"/>
                </Grid>
            </Grid>
        </Grid>

        <EntitySwitch>
            <EntitySwitch.Case if={isGitlabAvailable}>
                <Grid container spacing={GRID_SPACING} alignItems="stretch">
                    <Grid item md={6} xs={12}>
                        <EntityGitlabMergeRequestsTable/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <EntityGitlabPipelinesTable/>
                    </Grid>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </Grid>
);
