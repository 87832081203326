import React from "react";
import {
    EntityOrphanWarning, EntityProcessingErrorsPanel,
    EntitySwitch,
    hasCatalogProcessingErrors,
    isOrphan
} from "@backstage/plugin-catalog";
import {Grid} from "@material-ui/core";

export default (
    <>
        <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
                <Grid item xs={12}>
                    <EntityOrphanWarning/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
                <Grid item xs={12}>
                    <EntityProcessingErrorsPanel/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </>
)
