import React from "react";
import {EntitySwitch, isComponentType} from "@backstage/plugin-catalog";
import serviceEntityPage from "./ComponentPage/ServiceEntityPage";
import websiteEntityPage from "./ComponentPage/WebsiteEntityPage";
import defaultEntityPage from "./DefaultEntityPage";

export default (
    <EntitySwitch>
        <EntitySwitch.Case if={isComponentType('service')}>
            {serviceEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('website')}>
            {websiteEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
)
