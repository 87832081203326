import {EntitySwitch} from "@backstage/plugin-catalog";
import {EmptyState} from "@backstage/core-components";
import {Button, Grid} from "@material-ui/core";
import React from "react";
import {
    EntityGitlabPipelinesTable,
    isGitlabAvailable
} from "@immobiliarelabs/backstage-plugin-gitlab";
import {EntityCicdStatisticsContent} from "@backstage/plugin-cicd-statistics";

export default (
    // This is an example of how you can implement your company's logic in entity page.
    // You can for example enforce that all components of type 'service' should use GitHubActions
    <EntitySwitch>
        <EntitySwitch.Case if={isGitlabAvailable}>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12}>
                    <EntityGitlabPipelinesTable />
                </Grid>
                <Grid item xs={12}>
                    <EntityCicdStatisticsContent/>
                </Grid>
            </Grid>
        </EntitySwitch.Case>

        <EntitySwitch.Case>
            <EmptyState
                title="No CI/CD available for this entity"
                missing="info"
                description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
                    >
                        Read more
                    </Button>
                }
            />
        </EntitySwitch.Case>
    </EntitySwitch>
);
