import React, {useEffect, useState} from 'react'
import {Content, Header, Page} from "@backstage/core-components";
import {identityApiRef, useApi} from "@backstage/core-plugin-api";
import {catalogApiRef, EntityProvider} from "@backstage/plugin-catalog-react";
import * as O from "fp-ts/Option";
import {Entity, isUserEntity, UserEntity} from "@backstage/catalog-model";
import {pipe} from "fp-ts/function";
import * as TO from "fp-ts/TaskOption";
import {
    countEntityKind,
    fetchCaredEntityForUser,
    fetchLoggedProfile
} from "../Main/utils";
import {Container, Grid} from "@material-ui/core";
import {OwnershipCard, UserProfileCard} from "@backstage/plugin-org";
import HeaderClocks from "../HeaderClocks";
import {LoggedInUser} from "../Main/models";

export default () => {
    const identityApi = useApi(identityApiRef)
    const catalogApi = useApi(catalogApiRef)
    const [userEntity, setUserEntity] = useState<O.Option<UserEntity>>(O.none)


    const [userProfile, setUserProfile] = useState<LoggedInUser>(O.none)
    const [caredEntities, setCaredEntities] = useState<Entity[]>([])
    const [subtitle, setSubtitle] = useState('')

    useEffect(() => {
        fetchLoggedProfile(identityApi).then(setUserProfile)
    }, [identityApi])

    useEffect(() => {
        fetchCaredEntityForUser(catalogApi, userProfile).then(setCaredEntities)
    }, [userProfile, catalogApi])

    useEffect(() => {
        if (caredEntities.length === 0) return

        const countedKinds = countEntityKind(caredEntities)
        const [hasSystem, hasComponents] = [countedKinds.System !== 0, countedKinds.Component !== 0]

        if (hasSystem || hasComponents) {
            const system = hasSystem ? `${countedKinds.System.toString()} Systems` : ''
            const and = hasSystem && hasComponents ? 'and' : ''
            const component = hasComponents ? `${countedKinds.Component.toString()} Components` : ''
            setSubtitle(`You are caring for ${system} ${and} ${component}`)
        }
    }, [caredEntities])

    useEffect(() => {
        pipe(
            TO.fromOption(userProfile),
            TO.chain(user => TO.tryCatch(() => catalogApi.getEntityByRef(user.userEntityRef))),
            TO.chain(res => TO.fromNullable(res)),
            TO.chainOptionK(entity => O.fromNullable(isUserEntity(entity) ? entity : undefined))
        )().then(setUserEntity)
    }, [identityApi, catalogApi, userProfile])

    const EntityUserContent = (user: UserEntity) => (<EntityProvider entity={user}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <UserProfileCard variant="gridItem"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <OwnershipCard hideRelationsToggle variant="gridItem"/>
            </Grid>
        </Grid>
    </EntityProvider>)

    const ErrorOrLoading = () => (<Container>
        <div>Could not find you in the entities</div>
    </Container>)


    return (
        <Page themeId="home">

            <Header title="Your universe" subtitle={subtitle}>
                <HeaderClocks />
            </Header>

            <Content>
                {pipe(
                    userEntity,
                    O.map(EntityUserContent),
                    O.getOrElse(ErrorOrLoading)
                )}
            </Content>

        </Page>
    )
}
